import {
  EVENT_TYPE_LONG_LABEL,
  EVENT_TYPE_SHORT_LABEL,
  EventType,
} from '@/constants'
import { Event } from '@/types'

const ALLOWED_EVENT_TYPES = [
  EventType.DEPOSITION,
  EventType.EXAMINATION_UNDER_OATH,
]

export const isExaminationUnderOath = (event: Event) => {
  return event.type === EventType.EXAMINATION_UNDER_OATH
}

export const canUseDRScriptForEvent = (event: Event): boolean => {
  if (event.is_deposition_in_aid || event.is_court_reporter_event) {
    return false
  }

  return (
    ALLOWED_EVENT_TYPES.includes(event.type) &&
    (event.general_status === 'booked' ||
      (event.general_status === 'waiting' &&
        event.is_waiting_for_reporter_feedback))
  )
}

export const getEventTypeShortLabel = (event: Event): string => {
  return EVENT_TYPE_SHORT_LABEL[event.type]
}

export const getEventTypeLongLabel = (event: Event): string => {
  return EVENT_TYPE_LONG_LABEL[event.type]
}
