import { Alert, Button, Container, Group, Text, Title } from '@mantine/core'

import { ReporterSettings } from '@/components/ReporterSettings'
import { Shell } from '@/components/Shell'
import { useAuth } from '@/hooks/useAuth'
import { useModals } from '@/hooks/useModals'
import { useReporter } from '@/providers/UserProvider'
import { getCrucialFields } from '@/utils/parrot'

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
})

export const Settings = () => {
  const reporter = useReporter()
  const openSettingsChangedModal = useSettingsChangedModal()

  if (!reporter) {
    return null
  }

  return (
    <Shell header={{ middle: <Title order={6}>Settings</Title> }}>
      <Container size="sm" className="py-12">
        {reporter.has_missing_settings && (
          <Alert
            title="Finish your account setup before booking"
            color="orange"
            className="mb-6"
          >
            <Text size="sm">
              We are missing information about your commission. Please add{' '}
              {formatter.format(getCrucialFields(reporter))} before you can book
              an event.
            </Text>
          </Alert>
        )}

        {reporter.has_expired_commission && (
          <Alert
            title="Your commission has expired"
            color="red"
            className="mb-6"
          >
            <Text size="sm">
              Your commission expiration date has passed. Please update
              "commission expiration date" before you can book an event
            </Text>
          </Alert>
        )}

        <ReporterSettings
          reporter={reporter}
          onUpdated={updatedReporter => {
            openSettingsChangedModal(updatedReporter.email !== reporter.email)
          }}
          wide={true}
        />
      </Container>
    </Shell>
  )
}

const useSettingsChangedModal = () => {
  const { logout } = useAuth()
  const modals = useModals()

  return (emailChanged: boolean) => {
    modals.openModal({
      title: 'Your settings were changed',
      children: (
        <Group>
          <Text size="sm">
            {emailChanged
              ? 'Your settings were saved. Please verify your email and sign in again.'
              : 'We will refresh the browser for you now'}
          </Text>
          <Button
            onClick={() => {
              // if email is changed reporter will receive email verification link
              // and user has to login again when email is verified
              if (emailChanged) {
                logout({ returnTo: window.location.origin })
              } else {
                window.location.reload()
              }
            }}
            fullWidth
          >
            Continue
          </Button>
        </Group>
      ),
      centered: true,
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
    })
  }
}
