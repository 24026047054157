import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, LoadingOverlay, Stack, Text } from '@mantine/core'
import * as yup from 'yup'

import { EventCancelledCard } from './EventCancelledCard/EventCancelledCard'

import { useNewScriptTraining } from '@/components/Event/hooks/useNewScriptTraining'
import { AttendeeCard } from '@/components/Event/NewScript/components/common/AttendeeCard/AttendeeCard'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { LocationForm } from '@/components/Event/NewScript/components/common/forms/LocationForm'
import {
  ActionContainer,
  ContinueButton,
  ScriptTimeline,
  TimelineAction,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { EventCompletedCard } from '@/components/Event/NewScript/components/Review/EventCompletedCard'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { ScriptStep } from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import { Event } from '@/types'

const ReviewStepSchema = yup.object({
  state: yup.string().required('State is required'),
  county: yup.string().required('County is required'),
})

type ReviewStepType = yup.InferType<typeof ReviewStepSchema>

type EventCancelledReviewFormProps = {
  event: Event
  refetchEvent?: () => Promise<unknown>
}

export const EventCancelledReviewForm: React.FC<
  EventCancelledReviewFormProps
> = ({ event, refetchEvent }) => {
  const { completeAction, isActionCompleted, canContinue } = useStepActions(
    ScriptStep.REVIEW,
    event,
  )

  const { user } = useAuth()
  const { state, actions, isLoading, isSubmittingScript, submitScript } =
    useScriptState(event)
  const { hasTrainingAccess, resetScript, isResetting } =
    useNewScriptTraining(event)

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(ReviewStepSchema),
    defaultValues: {
      state: state.state,
      county: state.county,
    },
  })

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name === 'state') {
        return actions.updateState(value.state)
      }

      if (name === 'county') {
        return actions.updateCounty(value.county)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, state, actions])

  const validateAndCompleteAction = async (
    validation_keys: Array<keyof ReviewStepType>,
    action_key: string,
  ) => {
    const results: Promise<boolean>[] = []

    validation_keys.forEach(key => {
      results.push(methods.trigger(key))
    })

    Promise.all(results).then(res => {
      res.every(isValid => isValid) && completeAction(action_key)
    })
  }

  if (isLoading) {
    return <LoadingOverlay visible />
  }

  return (
    <FormProvider {...methods}>
      <Stack align="flex-start" spacing="xl">
        <ScriptTimeline title="6. Review">
          <TimelineAction
            actionKey="deposition_ended"
            title="Deposition ended"
            onComplete={completeAction}
            completed={isActionCompleted('deposition_ended')}
          >
            <EventCancelledCard event={event} />
          </TimelineAction>
          <TimelineAction
            actionKey="attendees"
            title="Attendee information"
            onComplete={completeAction}
            completed={isActionCompleted('attendees')}
          >
            <ActionContainer outer>
              <Stack>
                {state.attendees.map((attendee, index) => (
                  <AttendeeCard
                    isPresenceDisabled={true}
                    event={event}
                    key={attendee.attendee_id}
                    attendee={attendee}
                    namePrefix={`attendees.${index}`}
                  />
                ))}
              </Stack>
            </ActionContainer>
          </TimelineAction>
          <TimelineAction
            actionKey="location"
            title="Declare your location during proceeding"
            onComplete={() =>
              validateAndCompleteAction(['state', 'county'], 'location')
            }
            completed={isActionCompleted('location')}
          >
            <ActionContainer outer>
              <ActionContainer>
                <LocationForm />
              </ActionContainer>
            </ActionContainer>
          </TimelineAction>
          <ContinueButton
            event={event}
            loading={isSubmittingScript || isResetting}
            canContinue={
              canContinue() && event.is_waiting_for_reporter_feedback
            }
            handleClick={async () => {
              await methods.trigger()

              if (!hasTrainingAccess) {
                await submitScript(state)
                await refetchEvent?.()
              } else {
                resetScript()
              }
            }}
            label="Finish proceeding"
            step={ScriptStep.REVIEW}
          />
        </ScriptTimeline>
        {methods.formState.errors && (
          <FormErrors errors={methods.formState.errors} />
        )}
        {hasTrainingAccess && (
          <Alert title="Keep in mind" color="red">
            <Text>
              Please note, since this is a demo event the form will not be
              actually submitted. Instead the script will start over so you can
              try different scenarios, such as event cancellation process, etc.
            </Text>
          </Alert>
        )}
        {user?.role === 'admin' && !event.is_waiting_for_reporter_feedback && (
          <EventCompletedCard event={event} />
        )}
      </Stack>
    </FormProvider>
  )
}
