import React, { useEffect, useMemo } from 'react'
import { FieldPath, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert, Loader, Stack, Text } from '@mantine/core'
import { useDidUpdate } from '@mantine/hooks'
import * as Sentry from '@sentry/react'
import sortBy from 'lodash/sortBy'
import * as yup from 'yup'

import { useNewScriptTraining } from '@/components/Event/hooks/useNewScriptTraining'
import { AttendeeCard } from '@/components/Event/NewScript/components/common/AttendeeCard/AttendeeCard'
import { FormErrors } from '@/components/Event/NewScript/components/common/forms/FormErrors'
import { LocationForm } from '@/components/Event/NewScript/components/common/forms/LocationForm'
import { NotesForm } from '@/components/Event/NewScript/components/common/forms/NotesForm'
import { ShownExhibitsFieldArray } from '@/components/Event/NewScript/components/common/forms/ShownExhibitsFieldArray'
import { SpellingsForm } from '@/components/Event/NewScript/components/common/forms/SpellingsForm'
import { TimestampsForm } from '@/components/Event/NewScript/components/common/forms/TimestampsForm'
import { IdentityDocumentCard } from '@/components/Event/NewScript/components/common/IdentityDocumentCard/IdentityDocumentCard'
import {
  ActionText,
  ActionTitle,
  ContinueButton,
} from '@/components/Event/NewScript/components/common/ScriptTimeline'
import { ActionContainer } from '@/components/Event/NewScript/components/common/ScriptTimeline/ActionContainer'
import { ScriptTimeline } from '@/components/Event/NewScript/components/common/ScriptTimeline/ScriptTimeline'
import { TimelineAction } from '@/components/Event/NewScript/components/common/ScriptTimeline/TimelineAction'
import { AttendeeWithTranscriptFieldCard } from '@/components/Event/NewScript/components/Review/AttendeeWithTranscriptFieldCard'
import { EventCompletedCard } from '@/components/Event/NewScript/components/Review/EventCompletedCard'
import { LowConfidenceWordsFieldArray } from '@/components/Event/NewScript/components/Review/LowConfidenceWordsFieldArray'
import { WitnessFieldCard } from '@/components/Event/NewScript/components/Review/WitnessFieldCard'
import { ROLES_WITH_ID } from '@/components/Event/NewScript/constants'
import { useScriptState } from '@/components/Event/NewScript/hooks/state/useScriptState'
import { useStepActions } from '@/components/Event/NewScript/hooks/state/useStepActions'
import { defaultReviewStepSchema } from '@/components/Event/NewScript/schemas/reviewStepSchema'
import { isExaminationUnderOath } from '@/components/Event/NewScript/utils/event'
import {
  AttendeeRole,
  DepositionResult,
  LowConfidenceWordStatus,
  ScriptStep,
} from '@/constants'
import { useAuth } from '@/hooks/useAuth'
import {
  useLowConfidenceWordsQuery,
  useShownExhibitsQuery,
} from '@/queries/events'
import { DRScriptData, Event } from '@/types'
import { isAttorney } from '@/utils/validations'

type ReviewStepType = yup.InferType<typeof defaultReviewStepSchema>

type DefaultReviewFormValues = {
  state: DRScriptData['state']
  county: DRScriptData['county']
  lowConfidenceWords: DRScriptData['low_confidence_words']
  timestamps: DRScriptData['timestamps']
  spellings: DRScriptData['spellings']
  notes: DRScriptData['notes']
  attendees: DRScriptData['attendees']
  shownExhibits: DRScriptData['shown_exhibits']
}

type DefaultReviewFormProps = {
  event: Event
  refetchEvent?: () => Promise<unknown>
}

export const DefaultReviewForm: React.FC<DefaultReviewFormProps> = ({
  event,
  refetchEvent,
}) => {
  const { completeAction, canContinue, isActionCompleted } = useStepActions(
    ScriptStep.REVIEW,
    event,
  )
  const { state, actions, submitScript, isSubmittingScript } =
    useScriptState(event)
  const isEUO = isExaminationUnderOath(event)
  const { hasTrainingAccess, resetScript, isResetting } =
    useNewScriptTraining(event)
  const { data: lowConfidenceAsrWords, error: lowConfidenceAsrWordsError } =
    useLowConfidenceWordsQuery(event, {
      enabled: state.low_confidence_words === null && !hasTrainingAccess,
      cacheTime: Infinity,
    })
  const { data: shownExhibitsData, isLoading: isExhibitsDataLoading } =
    useShownExhibitsQuery(event, {
      enabled: state.shown_exhibits === null && !hasTrainingAccess,
      cacheTime: Infinity,
    })

  const methods = useForm<DefaultReviewFormValues>({
    mode: 'all',
    resolver: yupResolver(defaultReviewStepSchema),
    context: { isDocumentNumberRequired: true },
    defaultValues: {
      state: state.state,
      county: state.county,
      lowConfidenceWords: state.low_confidence_words,
      shownExhibits: state.shown_exhibits,
      timestamps: state.timestamps,
      spellings: state.spellings,
      notes: state.notes,
      attendees: state.attendees,
    },
  })
  const { user } = useAuth()

  useEffect(() => {
    const lcwValue = methods.getValues('lowConfidenceWords')
    if (hasTrainingAccess && lcwValue === null) {
      methods.setValue('lowConfidenceWords', [])
    }
  }, [hasTrainingAccess, methods])

  useDidUpdate(() => {
    const lowConfidenceWords = (lowConfidenceAsrWords ?? []).map(word => ({
      ...word,
      status: LowConfidenceWordStatus.UNRESOLVED,
      correct_text: word.word,
    }))
    methods.setValue('lowConfidenceWords', lowConfidenceWords)
  }, [lowConfidenceAsrWords])

  useDidUpdate(() => {
    Sentry.captureException(
      new Error(
        `Couldn't load low confidence words ${JSON.stringify(
          lowConfidenceAsrWordsError,
        )}`,
      ),
    )
    methods.setValue('lowConfidenceWords', [])
  }, [lowConfidenceAsrWordsError])

  useDidUpdate(() => {
    // value may be null
    if (!shownExhibitsData) {
      return
    }

    methods.setValue('shownExhibits', sortBy(shownExhibitsData, 'index') ?? [])
  }, [shownExhibitsData])

  const { witness, attorneys } = useMemo(() => {
    return {
      witness: state.attendees.find(
        attendee => attendee.role === AttendeeRole.WITNESS,
      ),
      attorneys: state.attendees.filter(
        attendee => isAttorney(attendee.role) && attendee.present,
      ),
    }
  }, [state.attendees])

  const lowConfidenceWords = methods.watch('lowConfidenceWords')
  const shownExhibits = methods.watch('shownExhibits')

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
      if (name && ['timestamps', 'spellings', 'notes'].includes(name)) {
        return actions.updateConductingData({
          notes: value.notes,
          timestamps: value.timestamps,
          spellings: value.spellings,
        })
      }

      if (name === 'county') {
        return actions.updateCounty(value.county)
      }

      if (name === 'state') {
        return actions.updateState(value.state)
      }

      if (
        name === 'lowConfidenceWords' ||
        name?.includes('lowConfidenceWords')
      ) {
        return actions.updateLowConfidenceWords(value.lowConfidenceWords)
      }

      if (name === 'shownExhibits' || name?.includes('shownExhibits')) {
        return actions.updateShownExhibits(value.shownExhibits)
      }
    })
    return () => subscription.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.watch, actions])

  const validateAndCompleteAction = async (
    validationKeys: FieldPath<ReviewStepType>[],
    actionKey: string,
  ) => {
    const results = await Promise.all(
      validationKeys.map(key => methods.trigger(key)),
    )

    if (results.every(isValid => isValid)) {
      completeAction(actionKey)
    }
  }

  return (
    <FormProvider {...methods}>
      <Stack align="flex-start" spacing="xl">
        <ScriptTimeline title="6. Review">
          <TimelineAction
            actionKey="attendees"
            title="Attendee information"
            onComplete={key => validateAndCompleteAction(['attendees'], key)}
            completed={isActionCompleted('attendees')}
          >
            <ActionContainer outer>
              <Stack>
                {state.attendees.map((attendee, index) => (
                  <AttendeeCard
                    isPresenceDisabled={true}
                    isWithZoomParticipants={false}
                    emailEmptySlot={
                      attendee.transcript_order ? (
                        <Text color="red" italic>
                          fill in email address or remove order
                        </Text>
                      ) : null
                    }
                    event={event}
                    key={attendee.attendee_id}
                    attendee={attendee}
                    namePrefix={`attendees.${index}`}
                  />
                ))}
              </Stack>
            </ActionContainer>
          </TimelineAction>
          <TimelineAction
            actionKey="identity_documents"
            title="Identity documents"
            onComplete={() => {
              methods.trigger('attendees').then(isValid => {
                if (isValid) {
                  return completeAction('identity_documents')
                }
              })
            }}
            completed={isActionCompleted('identity_documents')}
          >
            <ActionContainer outer>
              <Stack>
                {state.attendees.map((attendee, index) => {
                  if (!ROLES_WITH_ID.includes(attendee.role)) {
                    return null
                  }

                  return (
                    <IdentityDocumentCard
                      event={event}
                      key={attendee.attendee_id}
                      attendee={attendee}
                      namePrefix={`attendees.${index}.identity_document`}
                    />
                  )
                })}
              </Stack>
            </ActionContainer>
          </TimelineAction>
          <TimelineAction
            title="Exhibits shown"
            actionKey="shown_exhibits"
            onComplete={() =>
              validateAndCompleteAction(['shownExhibits'], 'shown_exhibits')
            }
            completed={isActionCompleted('shown_exhibits')}
          >
            {isExhibitsDataLoading ? (
              <Loader />
            ) : (
              <ActionContainer outer>
                {!shownExhibits?.length ? (
                  <ActionContainer>
                    No exhibits were shown during the meeting using the Zoom app
                  </ActionContainer>
                ) : (
                  <Stack>
                    <ShownExhibitsFieldArray name="shownExhibits" />
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        Make sure that the exhibits are in the correct order in
                        which they were presented and that they are designated
                        for either the plaintiff or the defendant.
                      </ActionText>
                    </ActionContainer>
                  </Stack>
                )}
              </ActionContainer>
            )}
          </TimelineAction>
          <TimelineAction
            actionKey="examinations"
            title="Examination information"
            onComplete={completeAction}
            completed={isActionCompleted('examinations')}
          >
            <ActionContainer outer>
              <Stack>
                <ActionContainer>
                  <TimestampsForm />
                </ActionContainer>
                <ActionContainer>
                  <SpellingsForm />
                </ActionContainer>
                <ActionContainer>
                  <NotesForm />
                </ActionContainer>
              </Stack>
            </ActionContainer>
          </TimelineAction>
          {/* Transcript information */}
          {!isEUO && (
            <TimelineAction
              actionKey="transcript_information"
              title="Transcript information"
              onComplete={completeAction}
              completed={isActionCompleted('transcript_information')}
            >
              <ActionContainer outer>
                <Stack>
                  {witness && (
                    <WitnessFieldCard
                      witness={witness}
                      read={state.witness_requested_transcript as boolean}
                      onSubmitClick={value => {
                        actions.updateWitnessRequestedTranscript(value)
                      }}
                    />
                  )}
                  {attorneys.map(attendee => (
                    <AttendeeWithTranscriptFieldCard
                      key={attendee.attendee_id}
                      attendee={attendee}
                      onSubmitClick={value => {
                        actions.updateAttendee({
                          ...attendee,
                          transcript_order: value,
                        })
                      }}
                    />
                  ))}
                </Stack>
              </ActionContainer>
            </TimelineAction>
          )}
          {/* Low confidence words */}
          <TimelineAction
            title="Correct detected words with low confidence"
            actionKey="low_confidence_words"
            onComplete={() =>
              validateAndCompleteAction(
                ['lowConfidenceWords'],
                'low_confidence_words',
              )
            }
            completed={isActionCompleted('low_confidence_words')}
          >
            {lowConfidenceWords === null ? (
              <Loader />
            ) : (
              <ActionContainer outer>
                {lowConfidenceWords.length === 0 ? (
                  <ActionContainer>
                    There are no low confidence words, no action needed.
                  </ActionContainer>
                ) : (
                  <Stack>
                    <ActionContainer>
                      <LowConfidenceWordsFieldArray
                        name="lowConfidenceWords"
                        event={event}
                      />
                    </ActionContainer>
                    <ActionContainer>
                      <ActionTitle>Reminder</ActionTitle>
                      <ActionText>
                        Review the spelling of all words marked with the cross
                        in the transcript. Correct any inaccuracies by rewriting
                        the word in the input to the proper form and clicking
                        "correct" or click "unclear" if you are unsure.
                      </ActionText>
                    </ActionContainer>
                  </Stack>
                )}
              </ActionContainer>
            )}
          </TimelineAction>
          {/* Location */}
          <TimelineAction
            actionKey="location"
            title="Declare your location during proceeding"
            onComplete={() =>
              validateAndCompleteAction(['state', 'county'], 'location')
            }
            completed={isActionCompleted('location')}
          >
            <ActionContainer outer>
              <ActionContainer>
                <LocationForm />
              </ActionContainer>
            </ActionContainer>
          </TimelineAction>
          <ContinueButton
            event={event}
            canContinue={
              canContinue() &&
              (event.is_waiting_for_reporter_feedback || hasTrainingAccess)
            }
            loading={isSubmittingScript || isResetting}
            handleClick={async () => {
              await methods.trigger()

              if (!hasTrainingAccess) {
                await submitScript({
                  ...state,
                  result: DepositionResult.COMPLETED,
                })
                await refetchEvent?.()
              } else {
                resetScript()
              }
            }}
            label="Finish proceeding"
            step={ScriptStep.REVIEW}
          />
        </ScriptTimeline>
        {methods.formState.errors && (
          <FormErrors errors={methods.formState.errors} />
        )}
        {hasTrainingAccess && (
          <Alert title="Keep in mind" color="red">
            <Text>
              Please note, since this is a demo event the form will not be
              actually submitted. Instead the script will start over so you can
              try different scenarios, such as event cancellation process, etc.
            </Text>
          </Alert>
        )}
        {user?.role === 'admin' && !event.is_waiting_for_reporter_feedback && (
          <EventCompletedCard event={event} />
        )}
      </Stack>
    </FormProvider>
  )
}
