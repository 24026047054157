import React from 'react'
import { Link } from 'react-router-dom'
import { Alert, Button, Modal, Text } from '@mantine/core'
import { GearIcon } from '@radix-ui/react-icons'

import { useModals } from '@/hooks/useModals'
import { useReporter } from '@/providers/UserProvider'
import { getCrucialFields } from '@/utils/parrot'

const formatter = new Intl.ListFormat('en', {
  style: 'long',
  type: 'conjunction',
})

export const MissingReporterSettingsModal: React.FC<{
  opened: boolean
}> = ({ opened }) => {
  const modals = useModals()
  const reporter = useReporter()

  if (!reporter) {
    return null
  }

  return (
    <Modal
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      opened={opened}
      onClose={() => {}} // we do not enable the user to close the modal
      title="Finish your account setup"
    >
      <div className="flex flex-col space-y-4">
        <Alert color="orange">
          <div className="flex text-amber-500 justify-center p-1">
            <GearIcon className="w-12 h-12" />
          </div>
        </Alert>
        <Text size="sm">
          We are missing some crucial information. Please go to your settings
          and add {formatter.format(getCrucialFields(reporter))} before you can
          proceed.
        </Text>
        <div className="flex gap-x-2 mt-8 justify-end">
          <Button
            component={Link}
            to="/settings"
            onClick={() => modals.closeAll()}
          >
            Take me to settings
          </Button>
        </div>
      </div>
    </Modal>
  )
}
