import { Link, useParams } from 'react-router-dom'
import {
  Alert,
  Button,
  Container,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
} from '@mantine/core'
import { InfoCircledIcon } from '@radix-ui/react-icons'

import { EventTitle } from '@/components/common/EventTitle'
import { AvailableEventDetail } from '@/components/Event/Detail/AvailableEventDetail'
import { BookedEventDetail } from '@/components/Event/Detail/BookedEventDetail'
import { CancelledEventDetail } from '@/components/Event/Detail/CancelledEventDetail'
import { OverlappingEventDetail } from '@/components/Event/Detail/OverlappingEventDetail'
import { OverlappingRelatedEventDetail } from '@/components/Event/Detail/OverlappingRelatedEventDetail'
import { PastEventDetail } from '@/components/Event/Detail/PastEventDetail'
import { UnavailableEventDetail } from '@/components/Event/Detail/UnavailableEventDetail'
import { WaitingEventDetail } from '@/components/Event/Detail/WaitingEventDetail'
import { useNewScriptTraining } from '@/components/Event/hooks/useNewScriptTraining'
import { canUseDRScriptForEvent } from '@/components/Event/NewScript/utils/event'
import { NewScriptScreen } from '@/components/NewScriptScreen'
import { Shell } from '@/components/Shell'
import { useReporter } from '@/providers/UserProvider'
import { useReporterEventQuery } from '@/queries/reporterEvents'
import { ReporterFeedbackSaveStatus } from '@/routes/Event/components/ReporterFeedbackSaveStatus'
import { NotFound } from '@/routes/NotFound'

export const Event = () => {
  const { id } = useParams<{ id: string }>()
  const reporter = useReporter()

  const {
    data: event,
    isLoading,
    refetch,
    remove,
  } = useReporterEventQuery(id, reporter?.id, {
    refetchInterval: 20000,
    refetchOnWindowFocus: true,
  })

  const { hasTrainingAccess } = useNewScriptTraining(event)

  if (isLoading) {
    return (
      <Shell>
        <LoadingOverlay visible={true} />
      </Shell>
    )
  }

  if (event === undefined) {
    return <NotFound text="Event not found" />
  }

  if (event && canUseDRScriptForEvent(event)) {
    return (
      <NewScriptScreen
        event={event}
        refetchEvent={async () => {
          // Calling remove removes data from the query, so isLoading is true when refetch is triggered
          remove()
          await refetch()
        }}
      />
    )
  }

  const Detail = {
    available: AvailableEventDetail,
    booked: BookedEventDetail,
    past: PastEventDetail,
    overlapping: OverlappingEventDetail,
    overlapping_related: OverlappingRelatedEventDetail,
    waiting: WaitingEventDetail,
    cancelled: CancelledEventDetail,
    unavailable: UnavailableEventDetail,
  }[event.reporter_status]

  return (
    <Shell
      header={{
        middle: <EventTitle>{event.name}</EventTitle>,
        right: <ReporterFeedbackSaveStatus />,
      }}
    >
      <Container size="lg">
        <Paper p="xl">
          {hasTrainingAccess && (
            <Alert
              mb="md"
              color="red"
              title="Try out the new script demo"
              icon={<InfoCircledIcon />}
            >
              <Stack align="flex-start">
                <Text size="lg">
                  Please click on this button to access the demo
                </Text>
                <Button
                  color="red"
                  component={Link}
                  size="sm"
                  to={`/event/${event.id}/new-script`}
                >
                  Open the new script demo
                </Button>
              </Stack>
            </Alert>
          )}
          <Detail
            event={event}
            onChange={() => {
              // explicitly remove cache so we trigger loading state
              remove()
              refetch()
            }}
          />
        </Paper>
      </Container>
    </Shell>
  )
}
